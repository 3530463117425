<template>
	<div class="page">
		<div class="title">财务报表</div>

		<!-- filter -->
		<div class="filter_box">
		</div>
		<!-- table -->
		<el-table id="table" :data="tableData" :header-cell-style="tableHeaderStyle" :cell-style="tableCellStyle"
			style="width: 100%;">
			<el-table-column prop="name" label="报表名称"></el-table-column>
			<el-table-column prop="desc" label="报表描述"></el-table-column>
			<el-table-column label="操作" min-width="180" fixed="right">
				<template slot-scope="scope">
					<el-button @click="$router.push({ name: scope.row.url })" type="text" size="small"
						style="color: #e50112;">
						查看
					</el-button>
				</template>
			</el-table-column>
		</el-table>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [
					{name: '员工借支明细', desc: '员工当月借支明细情况', url: 'EmployeeDebitDetails'},
					{name: '员工借支扣减', desc: '员工当月借支扣减情况', url: 'EmployeeDebitDeduction'},
					// {name: '月工资表', desc: '查询当年的月工资情况', url: 'MonthlyPayroll'},
				],

				tableHeaderStyle: {
					backgroundColor: "#F5F8FA",
					textAlign: "center",
				},
				tableCellStyle: {
					textAlign: "center",
				},
			};
		},
		created() {

		},
		methods: {

		},
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 14px;
		font-weight: bold;
		color: #1c2438;
	}

	.filter_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0;

		.el-date-editor {
			height: 36px;
			margin-right: 10px;

			::v-deep .el-input__inner {
				height: 36px;
			}
		}

		.filter_search {
			display: flex;
			align-items: center;

			.filter_search_input {
				width: 350px;
				height: 36px;
				margin-right: 10px;

				::v-deep .el-input__inner {
					height: 36px;
				}
			}
		}

		.filter_btns {
			display: flex;
			align-items: center;

			>div:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	.operations {
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			font-size: 14px;
			color: #406bff;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 13px;
			}
		}
	}
</style>
